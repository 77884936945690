import styled from "styled-components";
import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import { getAnchorStyles } from "@website-builder/utilities/utils/DesignUtils.js";
export const SimplifiedListWrapper = styled.div`
	padding: 5rem 0rem;
	.eyebrow-label {
		text-align: center;
		margin-bottom: 1rem;
		color: var(--secondary-gray-dark);
		letter-spacing: 0.04rem;
		@media only screen and (min-width: ${TABLET_M_BP}px) {
			&.left {
				text-align: start;
			}
		}
	}
	.header-text {
		margin-bottom: 24px;
	}
	.logo-section-image {
		max-height: 38px;
		${({ isB2U }) => isB2U && "text-align: start;"};
	}
	.description-text {
		margin: 24px 0;
		${({ isB2U }) => isB2U && "text-align: start;"};
	}
	.price-card-button {
		margin: 24px 0 8px;
	}
	.bottom-cta {
		margin-top: 24px;
	}
	.list-header {
		margin-bottom: 1.5rem;
		&.center {
			text-align: center;
		}
	}
	.item-header {
		margin-bottom: 0.5rem;
	}
	.top-content {
		text-align: center;
	}
	&.two-column-section .top-content,
	&.two-column-section .bottom-content {
		margin: 0px;
	}
	.logo-list {
		margin-top: 1.5rem;
		align-items: ${({ isLeftSection }) => (isLeftSection ? "start" : "center")};
		justify-content: center;
	}
	.logo-section-seperator {
		margin-top: 1.5rem;
	}
	.bottom-content {
		.card-wrapper {
			width: 100%;
			text-align: center;
			margin: 0 0 auto 0;
			& .price,
			.description {
				margin: 16px 0 0;
			}
		}
		& > div:not(:only-child) {
			&:first-child {
				display: flex;
				&.left-content picture {
					width: 100%;
					& img {
						width: 100%;
					}
				}
			}
			&:last-child {
				display: flex;
				margin: auto;
			}
		}
	}
	.bottom-cta {
		width: 100%;
		text-align: center;
	}
	.bottom-description-text {
		margin-top: 24px;
		@media only screen and (max-width: ${TABLET_M_BP}px) {
			b {
				display: block;
				font-size: 2.5rem;
				line-height: 3.5rem;
				font-weight: 600;
			}
		}
	}
	.rich-text-wrapper {
		${({ isDarkBg }) => getAnchorStyles(isDarkBg)}
	}
	.star-component-separator {
		margin: 18px 0px;
	}
	.star-component {
		margin: 14px 0px;
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		text-align: center;
		padding: 40px 0;
		.titleAndDescriptionWithLink {
			justify-content: center;
		}
		.eyrbrow-label {
			text-align: center;
		}
		.header-text {
			margin-bottom: 16px;
			${({ isB2U }) => isB2U && "text-align: center;"};
		}
		.description-text {
			margin: 16px 0;
			${({ isB2U }) => isB2U && "text-align: center;"};
		}
		.list-header {
			${({ isB2U }) => isB2U && "text-align: start;"};
		}
		.bottom-cta {
			margin: 24px 24px 0;
			width: inherit;
		}
		justify-content: center;
		.bottom-content {
			gap: 2rem;
			& > div:not(:only-child) {
				&:last-child {
					margin: auto;
				}
			}
		}
	}
`;
export const ListItemWrapper = styled.div`
	text-align: start;
	.item-description {
		margin: 0;
	}
	.list-item {
		align-items: center;
	}
`;
